// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-investors-js": () => import("./../../../src/pages/about-us/investors.js" /* webpackChunkName: "component---src-pages-about-us-investors-js" */),
  "component---src-pages-awards-and-press-js": () => import("./../../../src/pages/awards-and-press.js" /* webpackChunkName: "component---src-pages-awards-and-press-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-products-4-tell-ai-js": () => import("./../../../src/pages/products/4tell-ai.js" /* webpackChunkName: "component---src-pages-products-4-tell-ai-js" */),
  "component---src-pages-products-e-save-js": () => import("./../../../src/pages/products/eSave.js" /* webpackChunkName: "component---src-pages-products-e-save-js" */),
  "component---src-pages-products-flash-io-iot-kit-js": () => import("./../../../src/pages/products/flashIO-iot-kit.js" /* webpackChunkName: "component---src-pages-products-flash-io-iot-kit-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-lucrys-analytics-js": () => import("./../../../src/pages/products/lucrys-analytics.js" /* webpackChunkName: "component---src-pages-products-lucrys-analytics-js" */),
  "component---src-pages-resources-blogs-how-artificial-intelligence-can-solve-manufacturing-challenges-during-covid-19-js": () => import("./../../../src/pages/resources/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19.js" /* webpackChunkName: "component---src-pages-resources-blogs-how-artificial-intelligence-can-solve-manufacturing-challenges-during-covid-19-js" */),
  "component---src-pages-resources-blogs-index-js": () => import("./../../../src/pages/resources/blogs/index.js" /* webpackChunkName: "component---src-pages-resources-blogs-index-js" */),
  "component---src-pages-resources-case-studies-index-js": () => import("./../../../src/pages/resources/case-studies/index.js" /* webpackChunkName: "component---src-pages-resources-case-studies-index-js" */),
  "component---src-pages-resources-case-studies-jspl-js": () => import("./../../../src/pages/resources/case-studies/jspl.js" /* webpackChunkName: "component---src-pages-resources-case-studies-jspl-js" */),
  "component---src-pages-resources-case-studies-kmml-js": () => import("./../../../src/pages/resources/case-studies/kmml.js" /* webpackChunkName: "component---src-pages-resources-case-studies-kmml-js" */),
  "component---src-pages-resources-case-studies-stanzen-js": () => import("./../../../src/pages/resources/case-studies/stanzen.js" /* webpackChunkName: "component---src-pages-resources-case-studies-stanzen-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

